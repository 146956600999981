import { useRef, useState } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import WatchSummary from "../Watch/Summary"
import { useMultilang } from "@/hooks/useMultilang"
import { convertWatch } from "./utils"

const defaultSwiper = {
  isBeginning: true,
  isEnd: false,
}

const ItemSwiper = ({ items = [] }) => {
  const { multilang } = useMultilang()
  const prevRef = useRef(null)
  const nextRef = useRef(null)
  const [swiper, setSwiper] = useState(defaultSwiper)
  const convert = convertWatch(multilang)
  const watches = items.map(convert)
  if (items.length < 1) return null

  function onSlideChange(swiper) {
    const { isBeginning, isEnd } = swiper
    setSwiper({ isBeginning, isEnd })
  }

  return (
    <div className="flex items-center">
      <button
        ref={prevRef}
        className={`flex justify-center items-center min-w-[37px] h-[37px] mr-5 
          ${items.length < 5 || swiper.isBeginning ? "invisible" : ""} 
        `}
      >
        <img
          src="/icon/arrow-down.svg"
          className="rotate-90 w-4 h-4 tablet:w-6 tablet:h-6 "
          alt="arrow"
        />
      </button>
      {items.length < 5 ? (
        <div className="flex w-full">
          {watches.map((watch, i) => {
            return (
              <div key={i} className="w-[24%] mx-2.5  pb-2">
                <WatchSummary {...watch}></WatchSummary>
              </div>
            )
          })}
        </div>
      ) : (
        <Swiper
          modules={[Navigation]}
          spaceBetween={25}
          slidesPerView={4}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSlideChange={onSlideChange}
          onSwiper={(swiper) => {
            setTimeout(() => {
              if (!swiper.destroyed) {
                swiper.params.navigation.prevEl = prevRef.current
                swiper.params.navigation.nextEl = nextRef.current
                swiper.navigation.init()
                swiper.navigation.update()
              }
            })
          }}
        >
          {watches.map((watch, i) => (
            <SwiperSlide key={i} className="p-2.5">
              <WatchSummary {...watch}></WatchSummary>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
      <button
        ref={nextRef}
        className={`flex justify-center items-center min-w-[37px] h-[37px] ml-5 
          ${items.length < 5 || swiper.isEnd ? "invisible" : ""} 
        `}
      >
        <img
          src="/icon/arrow-down.svg"
          className="-rotate-90 w-4 h-4 tablet:w-6 tablet:h-6 "
          alt="arrow"
          width={37}
          height={37}
        />
      </button>
    </div>
  )
}

export default ItemSwiper
