import Button from "@/components/common/Button"
import {
  getCaseMaterialUrl,
  getDialColorUrl,
  getConditionUrl,
} from "@/services/utils"
import Link from "next/link"
import { useMultilang } from "@/hooks/useMultilang"
import { useCurrency } from "@/hooks/useCurrency"
import useDetailData from "@/hooks/useDetailData"
import { base64Encode } from "@/services/utils/common"
const DetailCard = ({ detail = null }) => {
  const { t } = useMultilang()
  const { brand, model, refNo } = useDetailData(detail)
  const { price } = useCurrency()
  const caseMaterialUrl = getCaseMaterialUrl(detail.caseMaterial)
  const dialColorUrl = getDialColorUrl(detail.dialColor)
  const conditionUrl = getConditionUrl(detail.condition)

  if (!detail) return null

  return (
    <div className="flex flex-col justify-center w-full tablet:flex-row">
      <div
        style={{ backgroundImage: `url(${detail?.resources[0]?.uri})` }}
        className="z-0 w-full pt-[100%] bg-cover bg-center bg-no-repeat shadow-lg tablet:w-[490px] tablet:h-[490px] tablet:pt-0 tablet:-mr-[15px]"
      ></div>
      <div className="z-[1] flex justify-center px-[35px] mb-1 -mt-[70px] tablet:w-[490px] tablet:h-[490px] tablet:px-0 tablet:-ml-[15px] tablet:mt-[20px]">
        <div className="flex flex-col justify-center items-center bg-white font-bold w-full p-6 shadow-lg text-center tablet:items-start tablet:p-11 tablet:text-left">
          <div className="tracking-wider opacity-[.58] mb-3 uppercase tablet:mb-5">
            {brand}
          </div>
          <div className="mb-3 tablet:mb-5">{model || ""}</div>
          <div className="mb-7 tablet:mb-5">{refNo || ""}</div>
          <div className="hidden font-semibold text-xs mb-2.5 tablet:block">
            {t("detail.about-watch")}
          </div>
          <div className="hidden tablet:flex tablet:mb-5">
            <ul className="flex flex-wrap justify-around font-sans text-xs">
              <li className="flex flex-col items-center justify-between pt-2 px-[5px] tablet:pr-[10px]">
                <img
                  title={detail.condition}
                  src={conditionUrl}
                  alt={detail.condition}
                  width={28}
                  height={42}
                />
                <p className="text-black-300 text-center max-w-[50px] tablet:max-w-[70px]">
                  {t("detail.watch-condition")}
                </p>
              </li>
              <li className="flex py-4">
                <div className="w-px border-l"></div>
              </li>
              <li className="flex flex-col items-center justify-center px-[5px] pt-2 tablet:px-[10px]">
                <img
                  title={detail.dialColor}
                  src={dialColorUrl}
                  alt={detail.dialColor}
                  width={30}
                  height={30}
                />
                <p className="text-black-300 w-12 text-center mt-[19px]">
                  {t("detail.about-color")}
                </p>
              </li>
              <li className="flex py-4">
                <div className="w-px border-l"></div>
              </li>
              <li className="flex flex-col items-center justify-center px-[5px] pt-2 tablet:px-[10px]">
                <img
                  title={detail.caseMaterial}
                  src={caseMaterialUrl}
                  alt={detail.caseMeterial}
                  width={30}
                  height={30}
                />
                <p className="text-black-300 w-12 text-center mt-[19px]">
                  {t("detail.about-material")}
                </p>
              </li>
              <li className="flex py-4">
                <div className="w-px border-l"></div>
              </li>
              <li className="flex flex-col justify-center px-[5px] pt-3.5 tablet:px-[10px]">
                <p className="text-[26px]">
                  {detail.caseDimWmm}
                  <span className="text-[10px]">mm</span>
                </p>
                <p className="text-black-300 w-[58px] text-center mt-[19px]">
                  {t("detail.about-size")}
                </p>
              </li>
            </ul>
          </div>
          <div className="mb-5">{price(detail.amount, detail.currency)}</div>
          <div>
            <Link href={`/watch/${base64Encode(detail.id)}`}>
              <a>
                <Button className="min-w-[152px] bg-black-900">
                  {t("btn.view")}
                </Button>
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailCard
