import { useMemo } from "react"
import { useMultilang } from "@/hooks/useMultilang"
import { LazyLoadImage } from "@/components/common/Image"
import { Trans } from "next-i18next"

const HowItWorks = () => {
  const { t, multilang } = useMultilang()
  const howItWorksUrl = useMemo(() => {
    const urlMapping = {
      desktopEN: "/image/how_it_works/buy/Buy_EN.png",
      desktopSC: "/image/how_it_works/buy/Buy_SC.png",
      desktopTC: "/image/how_it_works/buy/Buy_TC.png",
      mobileEN: "/image/how_it_works/buy/Buy_Mobile_EN.png",
      mobileSC: "/image/how_it_works/buy/Buy_Mobile_SC.png",
      mobileTC: "/image/how_it_works/buy/Buy_Mobile_TC.png",
    }
    return {
      desktop: multilang(urlMapping, "desktop"),
      mobile: multilang(urlMapping, "mobile"),
    }
  }, [multilang])

  return (
    <div className="tablet:container tablet:px-[35px]">
      <div className="container px-[35px] mt-[50px] py-[50px] border border-[#fcfcfc] shadow-md">
        <div className="font-bold tablet:text-lg text-center mb-[25px] tablet:mb-11">
          <Trans
            defaults={t("how-it-work.buyer")}
            components={{
              span: <span className="border-b-2 border-ochre-200 pb-1" />,
            }}
          ></Trans>
        </div>
        <div>
          <div className="w-full">
            <div className="relative mb-10 px-5 tablet:px-0">
              <LazyLoadImage
                alt=""
                src={howItWorksUrl.desktop}
                className="hidden w-full tablet:block"
              />
              <LazyLoadImage
                alt=""
                src={howItWorksUrl.mobile}
                className="block w-full mx-auto tablet:hidden"
              />
            </div>
            <div className="grid tablet:grid-cols-2 tablet:gap-[30px]">
              <div>
                <div className="text-lg font-bold text-center mb-[25px] tablet:mb-12 tablet:text-left">
                  <Trans
                    defaults={t("how-it-work.why-kronos")}
                    components={{
                      span: (
                        <span className="border-b-2 border-ochre-200 pb-1" />
                      ),
                    }}
                  ></Trans>
                </div>
                <div className="mb-8">
                  <div className="flex mb-2.5">
                    <img src="/icon/money.svg" alt="" width={25} height={18} />
                    <div className="text-lg font-bold ml-2">
                      {t("how-it-work.title-1")}
                    </div>
                  </div>
                  <div className="text-sm">{t("how-it-work.content-1")}</div>
                </div>
                <div className="mb-8">
                  <div className="flex mb-2.5">
                    <img
                      src="/icon/guarantee.svg"
                      alt=""
                      width={25}
                      height={18}
                    />
                    <div className="text-lg font-bold ml-2">
                      {t("how-it-work.title-2")}
                    </div>
                  </div>
                  <div className="text-sm">{t("how-it-work.content-2")}</div>
                </div>
                <div>
                  <div className="flex mb-2.5">
                    <img src="/icon/eye.svg" alt="" width={25} height={18} />
                    <div className="text-lg font-bold ml-2">
                      {t("how-it-work.title-3")}
                    </div>
                  </div>
                  <div className="text-sm">{t("how-it-work.content-3")}</div>
                </div>
              </div>
              <div className="hidden grid-cols-2 gap-[3px] tablet:grid">
                <div>
                  <img
                    src="/image/how-it-works-1@2x.png"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <div>
                  <img
                    src="/image/how-it-works-2@2x.png"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <div>
                  <img
                    src="/image/how-it-works-3@2x.png"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
                <div>
                  <img
                    src="/image/how-it-works-4@2x.png"
                    alt=""
                    width="100%"
                    height="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
