import { useMemo } from "react"
import { useMultilang } from "@/hooks/useMultilang"
import { SearchBar } from "../Search"
const HomeSearch = ({ data = null, onSubmit }) => {
  const searchStyle = {
    input: {
      backgroundColor: "#fff",
    },
    button: {
      backgroundColor: "#fff",
    },
  }
  const { multilang } = useMultilang()
  const content = useMemo(() => {
    return {
      textContent: multilang(data, "textContent"),
      url: multilang(data, "imageUrl") || "/image/home-hero.png",
    }
  }, [multilang, data])

  return (
    <div
      style={{ backgroundImage: `url(${content.url})` }}
      className="relative h-[540px] -mt-[60px] tablet:h-[659px] tablet:-mt-[90px] bg-cover bg-center"
    >
      <div className="relative flex flex-col justify-center items-center w-full h-full px-5">
        <div className="font-bold text-[22px] text-white text-center mb-10 tablet:text-[32px] whitespace-pre-line">
          {content.textContent}
        </div>
        <div className="mb-20 w-full max-w-[913px]">
          <SearchBar
            inputStyle={searchStyle.input}
            buttonStyle={searchStyle.button}
            onSubmit={onSubmit}
          ></SearchBar>
        </div>
      </div>
    </div>
  )
}

export default HomeSearch
