import { useMultilang } from "@/hooks/useMultilang"
import { classNames, trimString } from "@/services/utils"
import Link from "next/link"
import { ArticleCardImage } from "@/components/Articles/ArticleCard"
import useToggle from "@/hooks/useToggle"

const titleTrim = trimString(50)
const introTrim = trimString(100)

const Article = ({ article = null }) => {
  const { multilang } = useMultilang()
  const [isHover, onLeave, onEnter] = useToggle()

  if (!article) return null
  return (
    <div className="relative max-w-full w-full h-full">
      <Link href={`/articles/${article.id}`}>
        <a>
          <ArticleCardImage
            alt={multilang(article, "title")}
            src={article.imageUrl}
            imageStyles={classNames(
              "object-cover w-full h-full",
              isHover ? "scale-150" : ""
            )}
            containerStyles="h-full"
          />
          <div
            className="absolute top-0 flex text-white w-full p-[25px] z-20"
            onMouseEnter={onEnter}
            onMouseLeave={onLeave}
          >
            <div className={`w-full p-5 bg-black-300/80`}>
              <h3 className="font-bold mb-5">
                {titleTrim(multilang(article, "title"))}
              </h3>
              <p className="font-medium text-sm">
                {introTrim(multilang(article, "intro"))}
              </p>
            </div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default Article
