import Link from "next/link"
import { useMultilang } from "@/hooks/useMultilang"

const Collections = ({ items = [] }) => {
  const { t, multilang } = useMultilang()
  const convert = (collection) => ({
    id: collection.data.id,
    name: multilang(collection.data, "brandName"),
    modelName: multilang(collection, "modelName"),
  })
  const collectionsData = items.map(convert)

  return (
    <div className="bg-green-400 w-full py-[60px] mt-[50px]">
      <div className="tablet:container tablet:px-[35px]">
        <div className="flex justify-center font-bold text-white text-lg mb-[25px] tablet:mb-10">
          {t("detail.featured-collections")}
        </div>
        <div className="grid grid-flow-col w-full overflow-x-auto gap-[25px] pt-1">
          {collectionsData.map((collection) => {
            return (
              <Link
                key={collection.id}
                href={`/watch?modelId=${collection.id}`}
              >
                <a className="relative transition-transform hover:-translate-y-1">
                  <div className="bg-[#333B38] flex flex-col items-center text-sm w-full px-10 py-[15px] rounded-lg whitespace-nowrap">
                    <div className="text-ochre-200 mb-5">{collection.name}</div>
                    <div className="text-white">{collection.modelName}</div>
                  </div>
                </a>
              </Link>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Collections
