import { useState, useEffect } from "react"
import BREAKPOINTS from "@/constants/breakpoints"

function useResize(resizeCallback) {
  const [resize, setResize] = useState()
  const handleResize = () => resizeCallback(setResize)

  useEffect(() => {
    // calls handleResize once on mount
    // https://github.com/vercel/next.js/discussions/17443
    // https://dev.to/adrien/creating-a-custom-react-hook-to-get-the-window-s-dimensions-in-next-js-135k
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return resize
}

export function handleTablet(setResize) {
  const isTablet = BREAKPOINTS.TABLET > window.innerWidth
  setResize(isTablet)
}

export default useResize
