import ItemSwiper from "@/components/Home/ItemSwiper"
import MobileItemList from "@/components/Home/MobileItemList"
import { useMultilang } from "@/hooks/useMultilang"
import useResize, { handleTablet } from "@/hooks/useResize"

const Pick = ({ items = [] }) => {
  const { t } = useMultilang()
  const isMobile = useResize(handleTablet)

  return (
    <div className="bg-white mx-auto pt-[30px] pb-[25px] px-[24px] border border-gray-200 shadow-md tablet:pb-10 tablet:px-[27px] tablet:max-w-[1140px]">
      <div className="font-bold text-lg text-black-900">
        {t("detail.kronos-pick")}
      </div>
      <div className="pt-[25px] tablet:pt-10">
        {isMobile ? (
          <MobileItemList items={items}></MobileItemList>
        ) : (
          <ItemSwiper items={items}></ItemSwiper>
        )}
      </div>
    </div>
  )
}

export default Pick
