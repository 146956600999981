import Link from "next/link"
import { useMultilang } from "@/hooks/useMultilang"
import { classNames } from "@/services/utils"

export function ViewMoreButton({ href, className }) {
  const { t } = useMultilang()

  return (
    <Link href={href}>
      <a
        className={classNames(
          "base-button inline-block px-5 py-2.5 normal-case",
          className
        )}
      >
        {t("home.discover.view-all-articles")}
      </a>
    </Link>
  )
}
