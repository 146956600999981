import DesktopDiscover from "./DesktopDiscover"
import MobileDiscover from "./MobileDiscover"
import { useMultilang } from "@/hooks/useMultilang"
import { ViewMoreButton } from "./ViewMoreButton"
import { ARTICLES } from "@/constants/route"
import useResize, { handleTablet } from "@/hooks/useResize"

const ARTICLE_DISPLAY_LIMIT = 3

const Discover = ({ items = [] }) => {
  const { t } = useMultilang()
  const articles = items.map((item) => item.article)
  const hasMore = articles.length > ARTICLE_DISPLAY_LIMIT
  const isMobile = useResize(handleTablet)

  return items.length < 3 ? null : (
    <div className="container px-[35px] mt-[50px]">
      <div className="font-bold mb-[25px] tablet:text-lg tablet:mb-10">
        {t("home.discover")}
      </div>
      {isMobile ? (
        <MobileDiscover items={articles} />
      ) : (
        <DesktopDiscover items={articles} />
      )}
      {hasMore ? (
        <div className="mt-[15px] text-center">
          <ViewMoreButton
            href={ARTICLES}
            className="bg-ochre-200 hover:bg-ochre-400"
          />
        </div>
      ) : null}
    </div>
  )
}

export default Discover
