import { useMultilang } from "@/hooks/useMultilang"
import { formatedDate } from "@/services/format"
import { useRouter } from "next/router"
import { classNames } from "@/services/utils"
import useToggle from "@/hooks/useToggle"
import { id } from "@/services/utils/common"
import { LazyLoadImage } from "@/components/common/Image"
import "react-lazy-load-image-component/src/effects/blur.css"

export function createArticleImgLink(src) {
  return `${src}?x-oss-process=image/resize,w_400, m_lfit`
}

export const getAspectRatio = (obj) =>
  obj.imageWidth && obj.imageLength ? obj.imageLength / obj.imageWidth : 1

export const getCardTopPadding = (ratio) => ({
  paddingTop: `${(ratio * 100).toFixed(2)}%`,
})

const ArticleCard = ({
  article,
  imageStyles,
  bgColour = "bg-white",
  lines = "line-clamp-3",
  titleLines = "",
}) => {
  const { multilang } = useMultilang()
  const { locale } = useRouter()
  const [isHover, onLeave, onEnter] = useToggle()
  const aspectRatio = getAspectRatio(article)
  const cardStyles = getCardTopPadding(aspectRatio)

  return (
    <>
      <ArticleCardImage
        imageStyles={classNames(imageStyles, isHover ? "scale-150" : "")}
        containerStyles="h-0"
        cardStyles={cardStyles}
        src={article?.imageUrl}
        alt={multilang(article, "title")}
      />
      <ArticleCardContent
        bgColour={bgColour}
        lines={lines}
        titleLines={titleLines}
        title={multilang(article, "title")}
        content={multilang(article, "intro")}
        date={formatedDate(article?.creationTime, locale)}
        onEnter={onEnter}
        onLeave={onLeave}
      />
    </>
  )
}

export default ArticleCard

export function ArticleCardImage({
  src,
  alt,
  imageStyles,
  containerStyles,
  cardStyles,
}) {
  return (
    <div
      className={classNames("overflow-hidden relative bg-red", containerStyles)}
      style={cardStyles}
    >
      <div className="absolute top-0 left-0 h-full w-full">
        <LazyLoadImage
          className={classNames(
            " hover:scale-150 w-full h-full object-cover",
            imageStyles
          )}
          effect="blur"
          src={src}
          alt={alt}
        />
      </div>
    </div>
  )
}

export function ArticleCardContent({
  title,
  content,
  date,
  bgColour,
  titleLines,
  lines,
  onEnter = id,
  onLeave = id,
}) {
  return (
    <div
      className={`flex flex-col gap-[15px] p-[15px] grow ${bgColour}`}
      onMouseEnter={onEnter}
      onMouseLeave={onLeave}
    >
      <p className={`font-semibold ${titleLines}`}>{title}</p>
      <p className={`text-sm ${lines}`}>{content}</p>
      <p className="text-gray-800 text-sm mt-auto">{date}</p>
    </div>
  )
}
