import { useMemo } from "react"
import ItemSwiper from "@/components/Home/ItemSwiper"
import MobileItemList from "@/components/Home/MobileItemList"
import { useMultilang } from "@/hooks/useMultilang"
import useResize, { handleTablet } from "@/hooks/useResize"

const Trend = ({ items = [] }) => {
  const filteredItems = useMemo(() => items.filter((item) => item.id), [items])
  const { t } = useMultilang()
  const isMobile = useResize(handleTablet)

  return (
    <div className="container px-[35px] mt-[50px]">
      <div className="font-bold tablet:text-lg">{t("detail.trending")}</div>
      {filteredItems.length < 1 ? null : (
        <div className="pt-[25px] tablet:pt-10">
          {isMobile ? (
            <MobileItemList items={filteredItems}></MobileItemList>
          ) : (
            <ItemSwiper items={filteredItems}></ItemSwiper>
          )}
        </div>
      )}
    </div>
  )
}

export default Trend
