const BREAKPOINT_LARGER_MOBILE = 360
const BREAKPOING_TABLET = 768
const BREAKPOINT_DESKTOP = 1140

export const MOBILE = "MOBILE"
export const LARGER_MOBILE = "LARGER_MOBILE"
export const TABLET = "TABLET"
export const DESKTOP = "DESKTOP"

const BREAKPOINTS = {
  LARGER_MOBILE: BREAKPOINT_LARGER_MOBILE,
  TABLET: BREAKPOING_TABLET,
  DESKTOP: BREAKPOINT_DESKTOP,
}

export default BREAKPOINTS
