import { Fragment, useEffect, useState } from "react"
import WatchSummary from "../Watch/Summary"
import { useMultilang } from "@/hooks/useMultilang"
import { convertWatch } from "./utils"

const MobileItemList = ({ items = [] }) => {
  const MAX_COUNT = 8
  const [watches, setWatches] = useState([])
  const [count, setCount] = useState(2)
  const { t, multilang } = useMultilang()
  const convert = convertWatch(multilang)
  const convertData = items.map(convert)
  const handleClick = () => {
    if (count === MAX_COUNT) {
      setCount(2)
    } else {
      setCount((c) => c + 2)
    }
  }

  useEffect(() => {
    const arr = convertData.slice(0, count)
    setWatches(arr)
  }, [count])
  if (items.length < 1) return null
  return (
    <Fragment>
      <div className="grid grid-cols-2 gap-2.5">
        {watches.map((watch, i) => (
          <WatchSummary key={i} {...watch}></WatchSummary>
        ))}
      </div>
      {count === MAX_COUNT ||
      items.length === watches.length ||
      items.length < 3 ? null : (
        <p
          className="text-xs text-gray-700 mt-5 text-center underline cursor-pointer"
          onClick={handleClick}
        >
          {t("btn.show-more")}
        </p>
      )}
    </Fragment>
  )
}

export default MobileItemList
