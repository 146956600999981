import Link from "next/link"
import { useState, useMemo } from "react"
import { useMultilang } from "@/hooks/useMultilang"

const Brands = ({ items = [] }) => {
  const { t, multilang } = useMultilang()
  const [showAll, setShowAll] = useState(false)
  const convert = (brand) => ({
    id: brand.data.id,
    url: brand.brandIconUri || "/image/brand@2x.png",
    name: multilang(brand, "brandName"),
  })
  const brandsData = items.map(convert)
  const brands = useMemo(() => {
    if (showAll) {
      return brandsData
    } else {
      return brandsData.slice(0, 4)
    }
  }, [showAll, brandsData])
  const handleClick = () => {
    setShowAll((value) => !value)
  }

  if (items.length < 1) return null
  return (
    <div className="container px-[35px] mt-[50px]">
      <div className="font-bold tablet:text-lg uppercase">
        {t("detail.brands")}
      </div>
      <div className="px-[37px] pt-[25px] tablet:pt-10">
        <div
          className={`grid grid-cols-2 gap-y-7 gap-x-10 ${
            showAll ? "tablet:grid-cols-5" : "tablet:grid-cols-4"
          }`}
        >
          {brands.map((brand) => {
            return (
              <div
                key={brand.id}
                className="flex flex-col items-center justify-center"
              >
                <Link href={`/watch?brandId=${brand.id}`}>
                  <a className="rounded-full mb-2.5">
                    <div
                      style={{ backgroundImage: `url(${brand.url})` }}
                      className="w-[90px] h-[90px] overflow-hidden rounded-full bg-contain bg-no-repeat bg-center"
                    ></div>
                  </a>
                </Link>
                <p className="text-sm text-center font-medium">{brand.name}</p>
              </div>
            )
          })}
        </div>
        {items.length < 5 ? null : (
          <p
            className="text-sm text-gray-700 mt-[50px] text-center underline cursor-pointer"
            onClick={handleClick}
          >
            {showAll ? t("home.view-less-brand") : t("home.view-more-brand")}
          </p>
        )}
      </div>
    </div>
  )
}

export default Brands
