import Article from "./Article"
import ArticleCard from "@/components/Articles/ArticleCard"
import Link from "next/link"

const DesktopDiscover = ({ items = [] }) => {
  const articleLength = items.length

  if (articleLength < 3) return null

  return (
    <div className="grid grid-cols-4 gap-x-4">
      <div className="col-span-2">
        <Article article={items[0]} />
      </div>
      {items.slice(1, 3).map((article) => (
        <Link href={`/articles/${article?.id}`} key={article.id}>
          <a className="flex flex-col h-full">
            <ArticleCard
              article={article}
              bgColour="bg-[#f5f2ec]"
              imageStyles="aspect-[0.92] object-cover"
              lines="line-clamp-5"
            />
          </a>
        </Link>
      ))}
    </div>
  )
}

export default DesktopDiscover
