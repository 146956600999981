import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import Link from "next/link"
import ArticleCard from "@/components/Articles/ArticleCard"

const paginationParams = {
  el: ".discover-pagination",
  clickable: true,
  bulletClass: "custom-swiper-bullet",
  bulletActiveClass: "custom-swiper-bullet-active",
  renderBullet: (_, className) => {
    return `<div class="${className}"></div>`
  },
}

const MobileDiscover = ({ items = [] }) => {
  if (items.length < 1) return null
  const firstThree = items.slice(0, 3)

  return (
    <div className="w-full ">
      <Swiper pagination={paginationParams} modules={[Pagination]}>
        {firstThree.map((article) => (
          <SwiperSlide key={article.id}>
            <Link href={`/articles/${article.id}`}>
              <a className="flex flex-col">
                <ArticleCard
                  article={article}
                  bgColour="bg-[#f5f2ec]"
                  imageStyles="aspect-[0.92] object-cover"
                  lines="line-clamp-3"
                  titleLines="line-clamp-3"
                />
              </a>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="discover-pagination flex justify-center gap-2.5 mt-5" />
    </div>
  )
}

export default MobileDiscover
