import { useMemo } from "react"
import { useMultilang } from "@/hooks/useMultilang"

const useDetailData = (detail = null) => {
  const { multilang } = useMultilang()

  const brand = useMemo(() => {
    return multilang(detail.brand, "name") || detail?.customInfoBrand || ""
  }, [detail, multilang])

  const model = useMemo(() => {
    return multilang(detail.model, "name") || detail?.customInfoModel || ""
  }, [detail, multilang])

  const refNo = useMemo(() => {
    return detail?.refNo?.refNo || detail?.customInfoRefNo || ""
  }, [detail])

  return { brand, model, refNo }
}

export default useDetailData
