import DetailCard from "./DetailCard"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import { useMemo } from "react"
import { useMultilang } from "@/hooks/useMultilang"

const Latest = ({ items = [] }) => {
  const paginationParams = {
    el: ".latest-pagination",
    clickable: true,
    bulletClass: "custom-swiper-bullet",
    bulletActiveClass: "custom-swiper-bullet-active",
    renderBullet: (index, className) => {
      return `<div class="${className}"></div>`
    },
  }
  const filteredItems = useMemo(() => items.filter((item) => item.id), [items])
  const { t } = useMultilang()

  return (
    <div className="mt-[50px] tablet:container tablet:px-[35px]">
      <div className="container px-[35px] font-bold tablet:text-lg tablet:mx-auto tablet:px-0">
        {t("detail.latest")}
      </div>
      {filteredItems.length < 1 ? null : (
        <div className="w-full pt-[25px] tablet:pt-10 tablet:px-[72px]">
          <Swiper
            pagination={paginationParams}
            modules={[Pagination]}
            spaceBetween={10}
          >
            {filteredItems.slice(0, 3).map((item, i) => (
              <SwiperSlide key={i}>
                <DetailCard detail={item}></DetailCard>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="latest-pagination flex justify-center gap-2.5 mt-5"></div>
        </div>
      )}
    </div>
  )
}

export default Latest
