import { useMultilang } from "@/hooks/useMultilang"
import Link from "next/link"

const Gift = () => {
  const { t } = useMultilang()
  return (
    <div className="grid grid-rows-2 gap-0 mt-[50px] mb-3 tablet:container tablet:grid-cols-2 tablet:grid-rows-1 tablet:gap-10 tablet:mb-[50px]">
      <div
        className="relative flex justify-center items-center h-[200px]  bg-cover bg-center"
        style={{ backgroundImage: "url('/image/gift-for-her@2x.png')" }}
      >
        <Link href="/watch?gender=F">
          <a>
            <button className="relative text-white text-sm font-bold bg-white/0 w-[140px] h-14 border border-white tablet:text-lg tablet:w-[180px] transition-colors hover:bg-white hover:text-black-400">
              {t("home.gift-for-her")}
            </button>
          </a>
        </Link>
      </div>
      <div
        className="relative flex justify-center items-center h-[200px] bg-cover bg-center"
        style={{ backgroundImage: "url('/image/gift-for-him@2x.png')" }}
      >
        <Link href="/watch?gender=M">
          <a>
            <button className="relative text-white text-sm font-bold bg-white/0 w-[140px] h-14 border border-white tablet:text-lg tablet:w-[180px] transition-colors hover:bg-white hover:text-black-400">
              {t("home.gift-for-him")}
            </button>
          </a>
        </Link>
      </div>
    </div>
  )
}

export default Gift
